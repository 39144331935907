import React, { useEffect, useState } from 'react'
import '../assets/css/fontawesome.css'
import '../assets/css/templatemo-cyborg-gaming.css'
import '../assets/css/owl.css'
import '../assets/css/animate.css'
import '../vendor/bootstrap/css/bootstrap.min.css'

import profileImage from '../assets/images/profile-header.jpg';
import logo from '../assets/images/logo.png';
import game_banner from '../assets/images/popular-01.jpg'
import Nav from './Nav'
import Preloader from './Preloader'
import { Link } from 'react-router-dom'

import { useLocation } from 'react-router-dom';
import { Box, Button, Modal, TextField } from '@mui/material'
import { Toaster, toast } from 'react-hot-toast'
import { productsPerPartner } from '../Common'



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column"

};



const NewGame = () => {

    const [changedGame, setChangedGame] = useState(false);
    const [msisdn, setMsisdn] = useState("");
    const [msisdnError, setMsisdnError] = useState(true);


    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);




    const [games, setGames] = useState([]);
    const [filteredGames, setFilteredGames] = useState([]);
    const [fetchingGames, setFetchingGames] = useState(false);

    const [subscriptionIsValid, setSubscriptionIsValid] = useState(false);

    //fetch games
    useEffect(() => {

        setFetchingGames(true);

        fetch('http://51.15.95.94:9158/api/game/all-active')
            .then(response => response.json())
            .then(data => {

                setGames(data);
                sessionStorage.setItem("games", JSON.stringify(data));

                if (sessionStorage.getItem('ctg') != null) {
                    setFilteredGames(data.filter(card => card.category == sessionStorage.getItem('ctg')));



                } else {
                    setFilteredGames(data);
                }

                setFetchingGames(false);

            })
            .catch(error => {
                // console.error('Error fetching card data:', error);
                toast.error('Error fetching card data:', error);
                setFetchingGames(false);
            });
    }, [])

    useEffect(() => {
        //check if user has subscription
        if (msisdn.length >= 9 && msisdn.length < 13) {

            setSubscriptionIsValid(true);
            return;

            const requestBody = {
                subscriberId: `254${msisdn.slice(-9)}`,
            };

            fetch('http://51.15.95.94:9158/api/checkSubscription', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    console.log(data)
                    if (data.status == 'SUCCESS') {
                        setSubscriptionIsValid(true);
                    }
                })
                .catch(error => {
                    // console.error('Error fetching card data:', error);
                    toast.error('Error while checking for subscription!', error);
                    setFetchingGames(false);
                });
        }
    }, [msisdn])


    const[selectedGameUrl, setSelectedGameUrl] = useState();
    const handleClickDetails = (game) => {
        // sessionStorage.setItem("gameId", game.id);
        // sessionStorage.setItem("category", game.category);
        setSelectedGameUrl(game.url);

        setOpen(true);

    }

    const handleMsisdn = (msisdn) => {
        setMsisdn(msisdn)
        if (msisdn.length >= 9 && msisdn.length < 13) {
            setMsisdnError(false);
        } else {
            setMsisdnError(true);
        }
    }


    const [activateSms, setActivateSms] = useState("");


    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);

        //get marketerId/ partnerId
        const partnerId = urlParams.get('mktid');

        //get productId
        const productId = urlParams.get('prodId');



        if (productId == null || partnerId == null) {
            // toast.error("Parameter missing");
        } else {
            productsPerPartner.filter(p => p.partnerId == partnerId && p.productId == productId).map(prod => {
                setActivateSms(`sms:+${prod.shortCode}?body=${prod.activationkeyWord}`);
            })
        }

    }, [])



    const handleSubscribe = () => {
        if (msisdn.length >= 9 && msisdn.length < 13) {
            // alert("Thank you. Subscription is under way!");
            return true
        } else {
            return false
        }
    }

    return (
        <div
            style={{ background: "#1f2122" }}
        >
            <Nav />

            {/* <!-- ***** Preloader Start ***** --> */}
            {fetchingGames &&
                <Preloader />
            }

            {/* <!-- ***** Preloader End ***** -->*/}


            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="page-content">

                            {/* <!-- ***** Banner Start ***** --> */}
                            <div className="main-banner">
                                <div className="row">
                                    <div className="col-lg-7">
                                        <div className="header-text">
                                            <h6>Welcome To Libro Games</h6>
                                            <h4><em>Browse</em> Our Popular Games Here</h4>
                                            <div class="main-button">
                                                <a href="#popular">Browse Now</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- ***** Banner End ***** --> */}


                            {/* <!-- ***** Most Popular Start ***** --> */}


                            <div className="most-popular" id="popular">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="heading-section">
                                            <h4><em>Most Popular</em> Right Now</h4>
                                        </div>
                                        <div className="row">
                                            {filteredGames.filter(g => g.category == 'PUZZLE').map(game => {
                                                return (

                                                    <div
                                                        id={game.id}
                                                        className="col-lg-3 col-sm-6"
                                                        style={{ cursor: "pointer", border: "red" }}
                                                        onClick={() => handleClickDetails(game)}

                                                    >
                                                        {/* <Link to="game-description"> */}
                                                        <div className="item">
                                                            <img src={game.banner} alt="" className='banner-img' />
                                                            <h4>{game.name}<br /><span>{game.category}</span></h4>
                                                            <ul>
                                                                {/* <li><i class="fa fa-star"></i> 4.8</li> */}
                                                                {/* <li><i class="fa fa-download"></i> 2.3M</li> */}
                                                            </ul>
                                                        </div>
                                                        {/* </Link> */}
                                                    </div>

                                                )
                                            })}

                                            <div class="col-lg-12">
                                                <div class="main-button">
                                                    <a href="#all-games">More Games</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- ***** Most Popular End ***** --> */}



                            {/* <!-- ***** All Games Start ***** --> */}
                            <div className="most-popular" id="all-games">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="heading-section">
                                            <h4><em>All Games</em> All</h4>
                                        </div>
                                        <div className="row">

                                            {filteredGames.map(game => {
                                                return (
                                                    <div
                                                        id={game.id}
                                                        className="col-lg-3 col-sm-6"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => handleClickDetails(game)}
                                                    >
                                                        {/* <Link to="game-description"> */}
                                                        <div className="item">
                                                            <img src={game.banner} alt="" className='banner-img' />
                                                            <h4>{game.name}<br /><span>{game.category}</span></h4>
                                                            <ul>
                                                                {/* <li><i class="fa fa-star"></i> 4.8</li> */}
                                                                {/* <li><i class="fa fa-download"></i> 2.3M</li> */}
                                                            </ul>
                                                        </div>
                                                        {/* </Link> */}
                                                    </div>
                                                )
                                            })}


                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- *****  All Games End ***** --> */}

                        </div>
                    </div>
                </div>
            </div>

            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <p>Copyright © 2023 <a href="#">libro Gaming</a> Company. All rights reserved.
                            </p>
                        </div>
                    </div>
                </div>
            </footer>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <TextField
                        required
                        id="outlined-required"
                        label="Phone Number"
                        placeholder="25470000000"
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        autoFocus={true}
                        error={msisdnError}
                        onChange={(e) => handleMsisdn(e.target.value)}
                        sx={{ width: "100%" }}
                    />


                    {subscriptionIsValid ?
                        <a style={{ color: "#fff" }}
                            href={selectedGameUrl}>
                            <Button variant="contained" sx={{ margin: "auto", width: "100%", mt: 3 }}>
                                PLAY
                            </Button>
                        </a>

                        :
                        <a style={{ color: "#fff" }}
                            href={activateSms != "" ? activateSms : ""}>
                            <Button variant="contained" sx={{ margin: "auto", width: "100%", mt: 3 }}>
                                SUBSCRIBE
                            </Button>
                        </a>
                    }
                </Box>



                {/* <Stack spacing={2} direction="row">
                    <Button variant="text">Text</Button>
                    <Button variant="contained">Contained</Button>
                    <Button variant="outlined">Outlined</Button>
                </Stack> */}
            </Modal>

            <Toaster />




            {/* <!-- Scripts -->
            <!-- Bootstrap core JavaScript --> */}
            <script src="../vendor/jquery/jquery.min.js"></script>
            <script src="../vendor/bootstrap/js/bootstrap.min.js"></script>

            <script src="../assets/js/isotope.min.js"></script>
            <script src="../assets/js/owl-carousel.js"></script>
            <script src="../assets/js/tabs.js"></script>
            <script src="../assets/js/popup.js"></script>
            <script src="../assets/js/custom.js"></script>


        </div>
    )
}

export default NewGame