// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navContainer {
    /* border: 1px red solid; */
    position: sticky;
    top: 0;

    background-color: #fff;
    width: 100%;

    padding: 5px 0;
}

.navWrapper {
    max-width: 1050px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.navLeft {}

.navRight {}


.logo {
    height: 30px;
}

.logoText {
    font-family: "poppins";
    font-size: 12px;
    color: #6C6970;
    padding-bottom: 10px;
    color: #136207;
    opacity: 0.6;
}



.navContainer.is-sticky {
    position: fixed;
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    animation: slideDown 0.35s ease-out;
}


@keyframes slideDown {
    from {
        transform: translateY(-100%);
    }

    to {
        transform: translateY(0);
    }
}


@media screen and (max-width: 1050px) {
    .navWrapper {
        margin: 0 2.5%;
    }

    .logo {
        width: 30vw;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/nav.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,gBAAgB;IAChB,MAAM;;IAEN,sBAAsB;IACtB,WAAW;;IAEX,cAAc;AAClB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;;AAGA,UAAU;;AAEV,WAAW;;;AAGX;IACI,YAAY;AAChB;;AAEA;IACI,sBAAsB;IACtB,eAAe;IACf,cAAc;IACd,oBAAoB;IACpB,cAAc;IACd,YAAY;AAChB;;;;AAIA;IACI,eAAe;IACf,yCAAyC;IACzC,2BAA2B;IAC3B,mCAAmC;AACvC;;;AAGA;IACI;QACI,4BAA4B;IAChC;;IAEA;QACI,wBAAwB;IAC5B;AACJ;;;AAGA;IACI;QACI,cAAc;IAClB;;IAEA;QACI,WAAW;IACf;AACJ","sourcesContent":[".navContainer {\n    /* border: 1px red solid; */\n    position: sticky;\n    top: 0;\n\n    background-color: #fff;\n    width: 100%;\n\n    padding: 5px 0;\n}\n\n.navWrapper {\n    max-width: 1050px;\n    margin-left: auto;\n    margin-right: auto;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n\n.navLeft {}\n\n.navRight {}\n\n\n.logo {\n    height: 30px;\n}\n\n.logoText {\n    font-family: \"poppins\";\n    font-size: 12px;\n    color: #6C6970;\n    padding-bottom: 10px;\n    color: #136207;\n    opacity: 0.6;\n}\n\n\n\n.navContainer.is-sticky {\n    position: fixed;\n    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.1);\n    backdrop-filter: blur(10px);\n    animation: slideDown 0.35s ease-out;\n}\n\n\n@keyframes slideDown {\n    from {\n        transform: translateY(-100%);\n    }\n\n    to {\n        transform: translateY(0);\n    }\n}\n\n\n@media screen and (max-width: 1050px) {\n    .navWrapper {\n        margin: 0 2.5%;\n    }\n\n    .logo {\n        width: 30vw;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
